import { JsonValue, SutroError } from "sutro-common";

/**
 * @typedef {Object} StudioErrorOptions
 * @property {Error} [cause] - The underlying error that caused this error.
 * @property {JsonValue} [context] - Additional context information in JSON format.
 */

export const isStudioError = (error: Error | undefined): error is StudioError =>
  error instanceof StudioError || /StudioError/.test(error?.name ?? "");

export class StudioError extends SutroError {
  /**
   * StudioError: Create high quality errors within the Studio app.
   *
   * @param {string} message A static string that describes this error. It
   *                         should *not* be a string template or variable. A
   *                         static string makes analytics significantly easier
   *                         as we can query for that static string when
   *                         matching for errors.
   *
   * @param {StudioErrorOptions} options Additional options for your error.
   * @param {Error} options.cause The error that caused this new error to occur.
   * @param {JsonValue} options.context Any additional JSON serializable data
   *                                    that this error might need as context.
   */
  constructor(
    message: string,
    options?: { cause?: Error; context?: JsonValue }
  ) {
    super(message, options);
    Object.setPrototypeOf(this, StudioError.prototype);
    this.name = "StudioError";
  }
}
